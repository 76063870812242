import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { addCustomermarquee, getCustomerMarquee, deleteCustomer, UpdateCustomer, } from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import { Padding } from "@mui/icons-material";


const AddCustomerMarquee = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerCNIC, setCustomerCNIC] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [submit, setSubmit] = useState(false);

  function uploadCustomer() {
    props.addCustomermarquee(
      {
        customer_name: customerName,
        customer_phone: customerPhone,
        customer_address: customerAddress,
        customer_email: customerEmail,
        customer_cnic: customerCNIC,
      },
      handleVisible,
      handleDangerVisible
    );
  }

  const handleVisible = (msg) => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const clearStates = () => {
    setCustomerAddress("");
    setCustomerCNIC("");
    setCustomerEmail("");
    setCustomerName("");
    setCustomerPhone("");
    setSubmit(false);
  };

  const CustomerData = isEmpty(props.customerMarqueeList) ? [] : props.customerMarqueeList.reverse();
  useEffect(() => {
    props.getCustomerMarquee();
  }, []);

  return (
    <>
      <Container fluid>
      <div className="main">
        <Form>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana" >
                <Form.Label>
                  <b>Customer Name *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Customer Name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Customer Contry *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Customer Contry"
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Manual Code *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  minLength={13}
                  maxLength={13}
                  placeholder="Manual Code"
                  value={customerCNIC}
                  onChange={(e) => setCustomerCNIC(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Description </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  value={customerAddress}
                  onChange={(e) => setCustomerAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Customer Email </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Customer Email"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
              </Form.Group>
            </Col> */}
          </Row>

          <div className="sendDiv">
            {isEmpty(customerName) ||
              isEmpty(customerPhone) ? (
              <Button
                className="sendButton"
                disabled
                style={{ backgroundColor: "black" }}
                onClick={() => {
                  uploadCustomer();
                  // setSubmit(true);
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                className="sendButton"
                onClick={() => {
                  uploadCustomer();
                  // setSubmit(true);
                }}
              >
                Save
              </Button>
            )}
          </div>

          {/* <div className="sendDiv">
            <Button className="sendButton" onClick={() => { uploadCustomer(); }}> Save </Button>
          </div> */}

        </Form>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomermarquee: (data, handleVisible, handleDangerVisible) => dispatch(addCustomermarquee(data, handleVisible, handleDangerVisible)),
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    deleteCustomer: (id) => dispatch(deleteCustomer(id)),
    UpdateCustomer: (newData, oldData) => dispatch(UpdateCustomer(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  addCustomer: state.MarqueeReducer.addCustomer,
  isAddingCustomerData: state.MarqueeReducer.isAddingCustomerData,
  isFetchingCustomerDataMarquee: state.MarqueeReducer.isFetchingCustomerDataMarquee,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerMarquee);
