import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import 'jspdf-autotable';
import { filter } from "lodash";
import Modal from "react-bootstrap/Modal";
import SendIcon from '@mui/icons-material/Send';
import Select from 'react-select';
import Swal from 'sweetalert';
import { PostInvoice, getInvoice, getInvoicePdf } from "./InvoiceDetails.actions";
import { getLevel5Data } from "../../accountsLevels/accountsLevel.action";


const InvoiceList = (props) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [cashaccountNo, setCashaccountNo] = useState("");
  const [id, setId] = useState();
  const handleShow = (rowData) => {setId(rowData); setShow(true)};
  const handleClose = () => setShow(false);
  function handlePost()
  {
    props.PostInvoice({
        levelFiveId:cashaccountNo,
        type:type,
        invoice_id:id,
    })
  }
  const cashAccountNoTop =
  !isEmpty(props.level5List) &&
  props.level5List
    // .filter((x) => x.levelfiveData.levelFourId == level4Id)
    .map((y) => {
      let data = {
        value: y.levelfiveData.levelFiveId,
        label:
          y.levelfiveData.allLevelKey + " / " + y.levelfiveData.levelFiveName,
          values:y.levelFour.levelFourId
      };
      return data;
    });
    const handleChangeAccountNo = (selectedOption) => {
        setCashaccountNo(selectedOption.value);}
    useEffect(() => {
        props.GetInvoice();
        props.getLevel5Data();
    }, []);
    return (
        <>
            {(props.isFetchingInvoice ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">

                        <div>
                            <Form>
                                <MaterialTable
                                    title="Invoice Detail"
                                    columns={[
                                        { title: 'Invoice Date', field: 'invoice_date' },    
                                        { title: 'Customer Name', field: 'level_five.levelFiveName' },
                                        { title: 'Invoice Description', field: 'invoice_desc' },
                                        { title: 'Invoice Status', field: 'invoice_status' },
                                    ]}
                                    data={props.invoiceData}
                                    detailPanel={(rowData) => {
                                        return (
                                          <MaterialTable
                                            title="Invoice Detail"
                                            columns={[
                                              { title: "Invoice Description", field: "invoice_data_item_desc" },
                                              { title: "Invoice Uom", field: "invoice_data_uom" },
                                              { title: "Invoice Quantity", field: "invoice_data_quantity" },
                                              { title: "Invoice Price", field: "invoice_data_price"},
                                              { title: "Invoice Total Price", field: "invoice_data_value" }
                                            ]}
                                            data={rowData.invoice_data}
                                            options={{
                                              actionsColumnIndex: -1,
                                              toolbar: false,
                                            //   exportButton: true,
                                              sorting: true,
                                              paging: true,
                                              pageSize: 200, // make initial page size
                                              emptyRowsWhenPaging: false, // To avoid of having empty rows
                                              pageSizeOptions: [50, 100, 150, 200],
                                              headerStyle: {
                                                position: "sticky",
                                                top: 0,
                                                color: "#00BBBB",
                                                fontWeight: "550",
                                                onRowAdd: "none",
                                              },
                                            }}
                                          />
                                        );
                                      }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        // exportButton: true,
                                        // exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },
                                    }}
                                    actions={[
                                        (rowData) => {
                                          return rowData.invoice_status !== "Pending"
                                            ? {
                                              icon: SendIcon,
                                              disabled: true,
                                            }
                                            : {
                                              icon: SendIcon,
                                              disabled: false,
                                              onClick: (event, rowData) => {
                                                handleShow(rowData.invoice_id)
                                                //  props.PostInvoice(rowData.invoice_id);
                                              },
                                            };
                                        },
                                        (rowData) => {
                                          return isEmpty(rowData)
                                            ? {
                                              icon: LocalPrintshopIcon,
                                              disabled: true,
                                            }
                                            : {
                                              icon: LocalPrintshopIcon,
                                              disabled: false,
                                              onClick: (event, rowData) => {
                                                props.invoicePdf(rowData)
                                                // props.updateVoucherStatus1(rowData, updateTableQuery);
                                              },
                                            }
                                        },
                                      ]}

                                />
                            </Form>

                        </div>
                        <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Transation Method</Modal.Title>
              </Modal.Header>
                <Modal.Body>
                    <Row >
                    <Col xl="3" lg="3" md="3" xs="3"></Col>
                <Col xl="2" lg="2" md="2" xs="2" > <Button onClick={()=>setType("Bank")}>Bank</Button></Col>
                <Col xl="2" lg="2" md="2" xs="2"></Col>
                <Col xl="2" lg="2" md="2" xs="2"><Button  onClick={()=>setType("Cash")}>Cash</Button></Col>
                 <Col xl="3" lg="3" md="3" xs="3"></Col>
                 </Row>
                 <Row>
                  <Col xl="3" lg="3" md="3" xs="3"></Col>
                  {type==""?"":
                  type=="Cash"?
                  <Col xl="6" lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Select Cash Account:</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Cash Account..."
                        onChange={handleChangeAccountNo}
                        isDisabled={type==""?true:false}
                        // options={cashAccountNoTop}
                         options={filter(cashAccountNoTop, x => x.values == 1)}
                      />
                    </Form.Group>
                  </Col>:
                  <Col xl="6" lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Select Bank Account:</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Bank Account..."
                        onChange={handleChangeAccountNo}
                        isDisabled={type==""?true:false}
                        // options={cashAccountNoTop}
                         options={filter(cashAccountNoTop, x => x.values == 2)}
                      />
                    </Form.Group>
                  </Col>}
                  <Col xl="3" lg="3" md="3" xs="3"></Col>
                 </Row>
                </Modal.Body>
             <Modal.Footer>
             {type==""||cashaccountNo==""?
                <Button className="sendButton" style={{marginLeft:'40%'}} disabled>POST</Button>:
                        <Button style={{marginLeft:'40%'}}  className="sendButton"
                        onClick={handlePost}>
                    POST
                </Button>}
                {/* <Row>    
                <Col xl="5" lg="5" md="5" xs="5"></Col>  
                <Col xl="2" lg="2" md="2" xs="2">
              
                </Col> 
           
                </Row> */}

             </Modal.Footer>
            </Modal>
                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
      
      invoicePdf: (data) => dispatch(getInvoicePdf(data)),
        GetInvoice: () => dispatch(getInvoice()),
        PostInvoice: (data) => dispatch(PostInvoice(data)),
        getLevel5Data: () => dispatch(getLevel5Data()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingInvoice: state.InvoiceReducer.isFetchingInvoice,
    invoiceData: state.InvoiceReducer.invoiceData,
    level5List: state.accountsLevelReducer.level5List,
    

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceList);