import React, { useState } from 'react'
import { Container, Form, Row, Col, Button, FormGroup } from 'react-bootstrap';
import {
    addExpense, getExpense, deleteExpense, getBooking, getMasterControl,/*UpdateHRExpense */
    getBookingById,
    addInstallment
} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty, find } from "lodash";
import Select from 'react-select';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";

const AddInstallmentMarquee = (props) => {
    const [bookingId, setBookingId] = useState("");
    const [bookingName, setBookingName] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [paidAmount, setPaidAmount] = useState(isEmpty(props.bookingByIdList) ? "" : props.bookingByIdList[0].booking_total_price);
    const [remaininAmount, setRemainingAmount] = useState(0);
    const [newPaidAmount, setNewpaidAmount] = useState(0);
    const [submit, setSubmit] = useState(false);
    function uploadExpense() {
        props.addInstallment({
            booking_id: bookingId,
            booking_total_amount: isEmpty(props.bookingByIdList) ? "" : props.bookingByIdList[0].booking_total_price,
            booking_remaining_amount: isEmpty(props.bookingByIdList) ? "" : props.bookingByIdList[0].booking_remaining_price,
            installment_price: newPaidAmount,

        }, handleVisible, handleDangerVisible);
    }
    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }
    const handleChangeBooking = (selectedOption) => {
        console.log(selectedOption, "sop")
        setBookingId(selectedOption.value);
        setBookingName(selectedOption.label);

    }
    const handleChangeBookingById = (selectedOption) => {
        console.log(selectedOption, "sop")
        setBookingId(selectedOption.value);
        // setBookingName(selectedOption.label);
        props.getBookingById(selectedOption.value)

    }
    const getBookingList = !isEmpty(props.bookingList) && (props.bookingList.map((x) => { let data = { value: x.booking_id, label: `${x.booking_id} / ${x.booking_name} / ${x.booking_date}` }; return data; }));
    console.log('getBookiList', getBookingList, props.bookingList);
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }
    const clearStates = () => {
        // setExpenseName();
        // setExpenseDescription();
        setNewpaidAmount(0);
        props.bookingByIdList[0].booking_remaining_price = 0;
        props.bookingByIdList[0].booking_total_price = 0;
        setRemainingAmount(0);
        setSubmit(false);

    }
    useEffect(() => {
        // props.getExpense();
        //props.getMasterControl(1);
        props.getBooking();
        setPaidAmount(0);
    }, []);
    return (
        <>
            {(props.isFetchingExpenseData || props.isAddingExpenseData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (

                <Container fluid>
                    <Form>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select booking *</b></Form.Label>
                                    <Select
                                        isDisabled={isEmpty(props.bookingList)}
                                        placeholder="Select booking..."
                                        onChange={handleChangeBookingById}
                                        options={getBookingList}

                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label>
                                        <b>Total amount :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Total amount...."
                                        value={isEmpty(props.bookingByIdList) ? "" : props.bookingByIdList[0].booking_payable_price}
                                        disabled={true}
                                        onChange={(e) => setPaidAmount(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label>
                                        <b>Remaining amount :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Remaining Amount...."
                                        value={isEmpty(props.bookingByIdList) ? "" : props.bookingByIdList[0].booking_remaining_price}
                                        disabled={true}
                                        onChange={(e) => setRemainingAmount(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label>
                                        <b>New Paid amount *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        disabled={!isEmpty(props.bookingByIdList) && props.bookingByIdList[0].booking_remaining_price <= 0 ? true : false}
                                        placeholder="New Remaining Amount...."
                                        value={newPaidAmount}
                                        onChange={(e) => setNewpaidAmount(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            {bookingId == "" || newPaidAmount == 0 ?
                                <Button className="sendButton" disabled style={{ backgroundColor: 'black' }} onClick={() => { uploadExpense(); setSubmit(true); }} >Save</Button> :
                                <Button className="sendButton" onClick={() => { uploadExpense(); setSubmit(true); }} >Save</Button>}
                        </div>
                    </Form>



                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addInstallment: (data, handleVisible, handleDangerVisible) => dispatch(addInstallment(data, handleVisible, handleDangerVisible)),
        // getExpense: (id) => dispatch(getExpense(id)),
        // getMasterControl: (id) => dispatch(getMasterControl(id)),
        getBooking: () => dispatch(getBooking()),
        // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
        //deleteExpense: (menu_id) => dispatch(deleteExpense(menu_id)),
        getBookingById: (id) => dispatch(getBookingById(id))
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.MarqueeReducer.departmentList,
    isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
    isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
    expenseList: state.MarqueeReducer.expenseList,
    masterControlsList: state.MarqueeReducer.masterControlsList,
    bookingList: state.MarqueeReducer.bookingList,
    bookingByIdList: state.MarqueeReducer.bookingByIdList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddInstallmentMarquee);

