import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import { getCustomerMarquee, deleteCustomer, UpdateCustomer, } from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import { isEmpty, find } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";


const CustomerMarqueeList = (props) => {
    const CustomerData = isEmpty(props.customerMarqueeList) ? [] : props.customerMarqueeList.reverse();

    useEffect(() => {
        props.getCustomerMarquee();
    }, []);

    return (
        <>
            <Container fluid>
                {props.isFetchingHallData ? (
                    <div className="loader-div">
                        <Button variant="info" disabled>
                            <Spinner
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="info"
                            />
                            Fetching Customer Data
                        </Button>
                    </div>
                ) : (
                    <MaterialTable
                        style={{ zIndex: 0 }}
                        title="Customer"
                        columns={[
                            { title: 'Customer ID', field: 'customer_id' },
                            { title: 'Customer Name', field: 'customer_name' },
                            { title: 'Customer Contry', field: 'customer_phone' },
                            { title: 'Manual Code', field: 'customer_cnic' },
                            { title: 'Description', field: 'customer_address' },
                        ]}
                        data={CustomerData}
                        options={{
                            actionsColumnIndex: -1,
                            filtering: true,
                            exportButton: true,
                            paging: true,
                            pageSize: 50,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [50, 100, 150, 200],
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },
                            exportPdf: (columns, data) => {
                                const doc = new jsPDF();
                                //render:rowData=>rowData.hall_name
                                const columnTitles = [
                                    { title: 'Customer ID', field: 'customer_id', render: rowData => rowData.customer_id },
                                    { title: 'Customer Name', field: 'customer_name', render: rowData => rowData.customer_name },
                                    { title: 'Customer Contry', field: 'customer_phone', render: rowData => rowData.customer_phone },
                                    { title: 'Manual Code', field: 'customer_cnic', render: rowData => rowData.customer_cnic },
                                    { title: 'Description', field: 'customer_address', render: rowData => rowData.customer_address },
                                ]
                                    .map(columnDef => columnDef.title);
                                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                                const pdfData = data.map((rowData) => [rowData.customer_id, rowData.customer_name, rowData.customer_phone, rowData.customer_cnic, rowData.customer_address]);
                                let content = {
                                    startY: 50,
                                    startX: 5,
                                    head: [columnTitles],
                                    body: pdfData
                                };
                                doc.addImage(logo, 'PNG', 10, 1, 50, 50);
                                doc.setFontSize(20);
                                doc.setFont(undefined, 'bold')
                                doc.text(companyName, 50, 22, 0, 0);
                                doc.setFontSize(13);
                                doc.setFont('TimesNewRoman', 'Normal')
                                doc.text(companyAddress, 50, 29, 0, 0);
                                doc.text(companyNtn, 50, 36, 0, 0);
                                doc.autoTable(content);
                                doc.save(`Customer.pdf`);
                            },
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },

                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        {
                                            props.UpdateCustomer(newData, oldData)
                                        }
                                    }, 600);
                                }),
                            /*   onRowDelete: (oldData) =>
                                   new Promise((resolve) => {
                                       setTimeout(() => {
                                           resolve();
                                           props.deleteCustomer(oldData.customer_id);
                                           console.log(oldData, 'old')
                                       }, 600);
                                   }),*/
                        }}
                    />
                )}
            </Container>
        </>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerMarquee: () => dispatch(getCustomerMarquee()),
        deleteCustomer: (id) => dispatch(deleteCustomer(id)),
        UpdateCustomer: (newData, oldData) => dispatch(UpdateCustomer(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingCustomerDataMarquee: state.MarqueeReducer.isFetchingCustomerDataMarquee,
    customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMarqueeList);
