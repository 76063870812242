
import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
import { dashboardAdminAccountRoutes, dashboardAdminInventoryRoutes, adminHRRoutes } from "../routes";
import sidebarImage from "../../../Images/sidebar-3.jpg";


function ResponsiveDrawer(props) {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getSelectedRoutes = () => {
    if (props.moduleKey == 'AdminInventory' || window.localStorage.getItem('module_key') == 'admin_inventory') return dashboardAdminInventoryRoutes;
    else if (props.moduleKey == 'AdminAccounts' || window.localStorage.getItem('module_key') == 'admin_accounts') return dashboardAdminAccountRoutes;
    else if (props.moduleKey == 'AdminHRModules' || window.localStorage.getItem('module_key') == 'admin_hr') return adminHRRoutes;
    else if (props.moduleKey == "") return (
      window.localStorage.clear(),
      window.location.replace('/login')
    );
  }
  const getRoutes = (z) => {
    return z.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={getSelectedRoutes()} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            {<Switch>{getRoutes(getSelectedRoutes())}</Switch>}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      />
    </>
  );
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

const mapStateToProps = (state, ownProps, number) => ({
  moduleKey: state.accountReducer.moduleKey,
})

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);