import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddCategory from "./AddCategory";
import CategoryTableList from "./CategoryTableList";
import AddWarehouse from "./AddWarehouse";
import WareHouseTableList from "./WareHouseTableList";
import { isNull } from "lodash";


const TotalWarehouseData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 1) ?
                "AddWarehouse" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 2) ?
                    "WarehouseList" : ""} id="AddWarehouse" className="mb-3">

                <Tab eventKey="AddWarehouse" title="Add Warehouse"
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 1) ? false : true}>
                    <AddWarehouse />
                </Tab>
                <Tab eventKey="WarehouseList" title="Warehouse List"
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 2) ? false : true}>
                    <WareHouseTableList />
                </Tab>

            </Tabs>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalWarehouseData);