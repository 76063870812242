import API from "../../../../../global/api";
import { get, forEach, isNull, isEmpty, sumBy } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";

export const requestGetCustomer = () => {
    return {
        type: "REQUEST_GET_CUSTOMER"
    }
}

export const successGetCustomer = (data) => {
    return {
        type: "SUCCESS_GET_CUSTOMER",
        payload: data,
    }
}

export const errorGetCustomer = () => {
    return {
        type: "ERROR_GET_CUSTOMER"
    }
}
export const getCustomer = () => {
    return (dispatch) => {
        dispatch(requestGetCustomer())
        API.get('/invoice/get_invoice_customer').then((res) => {
            dispatch(successGetCustomer(res.data))
        }).catch((error) => {
            dispatch(errorGetCustomer())
        })
    }
}
//add Ivoice
export const requestAddInvoice = () => {
    return {
      type: "REQUEST_ADD_INVOICE",
    };
  };
  export const successAddInvoice = (data) => {
    return {
      type: "SUCCESS_ADD_INVOICE",
      payload: data,
    };
  };
  export const errorAddInvoice = () => {
    return {
      type: "ERROR_ADD_INVOICE",
    };
  };
  export const addInvoice = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
      dispatch(requestAddInvoice());
      API.post(`/invoice/add_invoice`, data)
        .then((res) => {
           dispatch(getInvoice());
          dispatch(successAddInvoice(data));
          handleVisible();
        })
        .catch((error) => {
          dispatch(errorAddInvoice());
          handleDangerVisible();
        });
    };
  };
  ///get Ivoice
  export const requestGetInvoice = () => {
    return {
        type: "REQUEST_GET_INVOICE"
    }
}

export const successGetInvoice = (data) => {
    return {
        type: "SUCCESS_GET_INVOICE",
        payload: data,
    }
}

export const errorGetInvoice = () => {
    return {
        type: "ERROR_GET_INVOICE"
    }
}
export const getInvoice = () => {
    return (dispatch) => {
        dispatch(requestGetInvoice())
        API.get('/invoice/get_invoice').then((res) => {
            dispatch(successGetInvoice(res.data))
        }).catch((error) => {
            dispatch(errorGetInvoice())
        })
    }
}
//pdf
export const getInvoicePdf = (data) => {
  console.log(data,"PDFdATA")
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "Invoice Report";
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
    // const demandName = "Receiving Name: " + issuanceName;
    // const IGPNumber = "Receiving #: " + resData.map((x) => x.receiving_id);
    // const Dates =
    //   !isEmpty(startDate) || !isEmpty(endDate)
    //     ? "Receiving Date " + " " + startDate + " to " + endDate
    //     : "Issuance Date - ";
    // const headers = [
    //   ["CODE", "MATERIAL NAME", "UOM", "PRICE", "QUANTITY", "VALUE"],
    // ];
    // const data = resData[0].receiving_data.map((elt) => [
    //   elt.inventory.warehouse.whKey +
    //     "-" +
    //     elt.inventory.category.cateKey +
    //     "-" +
    //     elt.inventory.invKey,
    //   elt.inventory.productName,
    //   elt.inventory.uom,
    //   elt.inventory.productPrice,
    //   elt.productQuantity,
    //   Number(elt.inventory.productPrice) * Number(elt.productQuantity),
    // ]);
    // let content = {
    //   startY: 220,
    //   startX: 10,
    //   head: headers,
    //   body: data,
    // };
    doc.addImage(logo, "PNG", 40, 15, 100, 100);
    doc.text(companyName, 150, 40, 0, 20);
    doc.setFontSize(13);
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(companyAddress, 150, 60, 0, 20);
    doc.text(companyNtn, 150, 80, 0, 20);
    doc.setFontSize(14);
    doc.setFont(undefined, "bold");
    doc.text(title, 240, 120);
    // doc.text(demandName, 200, 80, 0, 20);
    // doc.text(IGPNumber, 200, 120, 0, 20);
    // doc.text(Dates, 380, 120, 0, 20);
    // doc.autoTable(content);
    doc.save(
      `Invoice Report ${moment(new Date()).format(
        "YYYY-MM-DD"
      )}.pdf`
    );
}
////post Invoice
export const requestPostInvoice = () => {
    return {
      type: "REQUEST_POST_INVOICE",
    };
  };
  export const successPostInvoice = (data) => {
    return {
      type: "SUCCESS_POST_INVOICE",
      payload: data,
    };
  };
  export const errorPostInvoice = () => {
    return {
      type: "ERROR_POST_INVOICE",
    };
  };
  export const PostInvoice = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
      dispatch(requestPostInvoice());
      API.post(`/invoice/invoice_post`,data)
        .then((res) => {
           dispatch(getInvoice());
          dispatch(successPostInvoice(res.data));
        //   handleVisible();
        })
        .catch((error) => {
          dispatch(errorPostInvoice());
        //   handleDangerVisible();
        });
    };
  };