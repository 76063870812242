import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Swal from "sweetalert";
import { addInvoice, getCustomer } from "./InvoiceDetails.actions";
import moment from "moment";

const AddInvoice = (props) => {
  const [customer, setCustomer] = useState("");
  const [Date, setDate] = useState();
  const [addDesc, setAddDesc] = useState();
  const [ManualNo, setManualNo] = useState();
  const [saleTaxRegNo, setSaleTaxRegNo] = useState();
  const [NTN, setNTN] = useState();
  const [CNIC, setCNIC] = useState();
  // const [CNIC, setCNIC] = useState();
  const [inputField, setInputField] = useState([{
    description: "",
    uom:"",
    quantity: 0,
    perPiecePrice: 0,
    TotalPrice: 0,
}]);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const getCustomer = props.customerInfo.map((x) => {
    console.log(x,"tttttttttttt")
    let data = { label: x.levelFiveName, value: x.levelFiveId };
    return data;
  });


  function uploadDepartment() {
    props.addInvoice(
      {
       levelFiveId: customer, 
       invoice_desc:addDesc,
       invoice_date: Date,
       data:inputField
      },
      handleVisible,
      handleDangerVisible
    );
  }

  const clearStates = () => {
    // setDivisionId(0);
    // setDivisionName();
    // setDepartmentName();
    // setDepartmentDescription();
  };

  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const setCustomerInfo = (selectedOption) => {
    setCustomer(selectedOption.value);
  };
  const handleChange = (e, index, type) => {
    console.log(e, index, type,"test")
    const list = [...inputField];
     if (type == 'description') list[index]['description'] = e.target.value;
     if (type == 'intquantity') list[index]['quantity'] = e.target.value;
     if (type == 'uom') list[index]['uom'] = e.target.value;
     if (type == 'perPiecePrice') list[index]['perPiecePrice'] = e.target.value;
     if (type == 'perPiecePrice') list[index]['TotalPrice'] = list[index]['quantity']*list[index]['perPiecePrice'];
     setInputField(list);
};

const handleAddInput = () => {
    setInputField([...inputField, {    description: "",
    uom:"",
    quantity: 0,
    perPiecePrice: 0,
    TotalPrice: 0, }])
};

const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
};
  useEffect(() => {
    props.getCustomer();
  }, []);
  return (
    <>
      {props.isFetchingGetCustomer ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
              <Form>
                <Row>
                  <Col xl="6" lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Date</b></Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl="6" lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Customer:</b>
                      </Form.Label>
                      <Select

                        placeholder="Select Customer..."
                        onChange={setCustomerInfo}
                        options={getCustomer}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                 <Row>
                  <Col xl="6" lg="6" md="6" xs="6">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Additional Description</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setAddDesc(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  </Row>
                 {/* <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Manuall No</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setManualNo(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Registeration No</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setSaleTaxRegNo(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>NTN</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setNTN(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>CNIC</b></Form.Label>
                      <Form.Control
                        type="Number"
                        onChange={(e) => setCNIC(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                 
                </Row> */}
                {inputField.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Row>
                                        <Col xl="4" lg="4" md="6" sm="6">
                                            <Form.Group>
                                                <Form.Label>
                                                    <b>Description</b>
                                                </Form.Label>
                                                <Form.Control placeholder="Description...." name="Description" value={item.description} onChange={e => handleChange(e, i, 'description')}/>
                                            </Form.Group>
                                        </Col>
                                        <Col xl="2" lg="2" md="6" sm="6">
                                            <Form.Group>
                                                <Form.Label>
                                                    <b>UOM</b>
                                                </Form.Label>
                                                <Form.Control placeholder="UOM...." name="UOM" value={item.uom} onChange={e => handleChange(e, i, 'uom')}/>
                                            </Form.Group>
                                        </Col>
                                        <Col xl="2" lg="2" md="6" sm="6">
                                            <Form.Group className="mb-3">
                                                <Form.Label><b>Quantity:</b></Form.Label>
                                                <Form.Control type="number" placeholder="Quantity...." name="Quantity"  value={item.quantity} onChange={e => handleChange(e, i, 'intquantity')}/>
                                            </Form.Group>
                                        </Col>
                                        <Col xl="2" lg="2" md="6" sm="6">
                                            <Form.Group className="mb-3">
                                                <Form.Label><b>Price</b></Form.Label>
                                                <Form.Control type="number" placeholder="Per Piece Price...." name="PerPiecePrice" value={item.perPiecePrice} onChange={e => handleChange(e, i, 'perPiecePrice')} />
                                            </Form.Group>
                                        </Col>
                                        <Col xl="2" lg="2" md="6" sm="6">
                                            <Form.Group className="mb-3">
                                                <Form.Label><b>Total Price</b></Form.Label>
                                                <Form.Control type="number" placeholder="Total Price...." name="TotalPrice" disabled value={item.TotalPrice}  />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Row>

                                            <Col lg="12">
                                                {inputField.length - 1 == i && <IconButton title="Click to Add Demand"
                                                    onClick={handleAddInput}
                                                    style={{
                                                        float: "right",
                                                        color: "black",
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>}

                                                {inputField.length !== 1 && <IconButton title="Click to Remove Demand"
                                                    onClick={() => handleRemoveInput(i)}
                                                    style={{ float: "right", color: "black" }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </React.Fragment>
                            );
                        })}
                <div className="sendDiv">
                  {isEmpty(Date)||customer==""||inputField[0].description==""||inputField[0].quantity==0||inputField[0].perPiecePrice==0?
                  <Button
                    className="sendButton"
                    onClick={uploadDepartment}
                    disabled
                    style={{backgroundColor:'black'}}
                  >
                    Save
                  </Button>:
                  <Button
                    className="sendButton"
                    onClick={uploadDepartment}
              
                  >
                    Save
                  </Button>}
                </div>
              </Form>
            </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
     addInvoice: (data,handleVisible, handleDangerVisible) => dispatch(addInvoice(data,handleVisible, handleDangerVisible)),
    // GetHRDepartment: () => dispatch(GetHRDepartment()),
    getCustomer: () => dispatch(getCustomer()),
    // updateHRDepartment: (newData, oldData) => dispatch(updateHRDepartment(newData, oldData)),
    // deleteHRDepartment: (departmentId) => dispatch(deleteHRDepartment(departmentId)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingGetCustomer: state.InvoiceReducer.isFetchingGetCustomer,
  customerInfo: state.InvoiceReducer.customerInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice);
