import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddCustomerMarquee from "./AddCustomerMarquee";
import CustomerMarqueeList from "./CustomerMarqueeList";



const TotalCustomerData = (props) => {
    return (
        <>
            <Tabs >
                <Tab eventKey="AddCustomer" title="Add Customer">
                    <AddCustomerMarquee />
                </Tab>
                <Tab eventKey="CustomerList" title="Customer List">
                    <CustomerMarqueeList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => { return {}; };
const mapStateToProps = (state, ownProps) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalCustomerData);